import { setRemoteDefinitions } from '@nx/angular/mf';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { licenseKey } from '../../../../devextreme-license';
if (typeof window !== "undefined") {
    (window as any).DevExpress = (window as any).DevExpress || {};
    (window as any).DevExpress.config = {
        licenseKey: licenseKey
    };
}
fetch('/assets/module-federation.manifest.json')
    .then((res) => res.json())
    .then((definitions) => setRemoteDefinitions(definitions))
    .then(() => import('./bootstrap').catch((err) => console.error(err)));

// const api = `/api/v1/systems/manifest`;
// // fetch('/assets/module-federation.manifest.json')
// fetch(api)
//   .then((res) => res.json())
//   .then((definitions) => {
//     console.log('definitions', definitions);
//     setRemoteDefinitions(definitions)
//   })
//   .then(() => import('./bootstrap').catch((err) => console.error(err)));


// import('./bootstrap').catch((err) => console.error(err));
